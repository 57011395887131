.rhap_main-controls-button, .rhap_repeat-button, .rhap_time, .rhap_volume-button {
    color: #fff;
}

.rhap_progress-bar-show-download, .rhap_progress-indicator, .rhap_volume-indicator, .rhap_volume-bar {
    background-color: #fff;
}

.rhap_volume-bar, .rhap_progress-bar-show-download {
    background-color: #e4e4e4;
}